<template>
  <div class="content">
    <div class="content__party content__party--start">
      <h1 class="title title--big title--color">Контакты</h1>
    </div>
    <ul class="tabs-list">
      <li class="tabs-list__item">
        <router-link :to="{name: 'contacts-main'}" exact-active-class="tabs-list__link--active" class="tabs-list__link">Основные</router-link>
      </li>
      <li class="tabs-list__item">
        <router-link :to="{name: 'contacts-additional'}" exact-active-class="tabs-list__link--active" class="tabs-list__link">Дополнительные</router-link>
      </li>
      <li class="tabs-list__item">
        <router-link :to="{name: 'contacts-department'}" exact-active-class="tabs-list__link--active" class="tabs-list__link">Отделы</router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Contacts',
};
</script>
